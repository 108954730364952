@import url("https://use.typekit.net/nfr7yhz.css");

body {
  margin: 0;
  font-family: capitana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --PrimaryBlue: #0069E0;
  --DarkGray: #3A3A3A;
  --LightGray: #F5F5F5;
  --DropShadow:  0 1px 1px rgba(0,0,0,0.05), 
  0 2px 2px rgba(0,0,0,0.05), 
  0 4px 4px rgba(0,0,0,0.05), 
  0 8px 8px rgba(0,0,0,0.05),
  0 16px 16px rgba(0, 0, 0, 0.05);
  background-color: var(--LightGray);
}

.display-linebreak {
  white-space: pre-line;
}

.mainDiv {
  display: flex;
  flex-direction: column;
}
.content {
  min-height: fit-content;
  box-shadow: inset 0 0 0.25rem -0.25rem rgba(0,0,0,0.05);
}

/* Standard Modals */
.modal {
  display: block; /* Hidden by default */
  position: absolute; /* Stay in place */
  z-index: 100; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */
  backdrop-filter: blur(2px);
  overflow: hidden;
}

.modalContent {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border-radius: 15px;
  width: 400px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
          0 2px 2px rgba(0,0,0,0.05), 
          0 4px 4px rgba(0,0,0,0.05), 
          0 8px 8px rgba(0,0,0,0.05),
          0 16px 16px rgba(0, 0, 0, 0.05);
}

.yes {
  background-color: rgb(54, 190, 54) ;
}

.no {
  background-color: rgb(206, 64, 64) ;
}

@media only screen and (max-width: 800px) {
  .modalContent {
      width: 90%;
      box-sizing: border-box;
  }
}
