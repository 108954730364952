.verifyDiv {
    width: 50%;
    max-width: 600px;
    min-width: 300px;
    margin: auto;
    margin-top: 2.5rem;
    text-align: center;
}

.verifyDiv button {
    margin-top: 1rem;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 18px;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: var(--DropShadow);
}