.adminReview {
    background-color: white;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 15px;
}

.adminReview button {
    border: none;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    margin-right: 1rem;
    border-radius: 15px;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.approve {
    background-color: rgb(54, 190, 54) ;
}

.reject {
    background-color: rgb(206, 64, 64) ;
}