.signUpPageDiv {
    width: 50%;
    max-width: 600px;
    min-width: 300px;
    margin: auto;
    margin-top: 2.5rem;
    text-align: center;
}

.signUpPageDiv h1 {
    margin: 0
}


/* Form  */
.signUpForm {
    display: flex;
    flex-direction: column;
}

.signUpForm label  {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-align: left;
}

.signUpForm input[type="text"], .signUpForm input[type="password"], .signUpForm input[type="email"], .signUpForm select {
    border: none;
    padding: 0.85rem 0.7rem;
    outline: none;
    background-color: white;
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: var(--DropShadow);
}

.signUpForm input[type="submit"] {
    margin-top: 1.5rem;
    padding: 0.5rem 3.5rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    cursor: pointer;
}

.error {
    border: rgb(224, 66, 66) 3px solid !important;
}

.errorText {
    text-align: left;
    font-style: italic;
    margin-top: 0.25rem;
    margin-bottom: 0;
    color: rgb(66, 66, 66)
}

@media only screen and (max-width: 1000px) {
    .signUpPageDiv {
        width: 90%;
        max-width: auto;
        margin: auto;
        margin-top: 1rem;
    }
}