.resultsPageDiv {
    width: 50%;
    margin: auto;
    margin-top: 2.5rem;
}
.resultsPageDiv .noResults {
    text-align: center;
}

.resultsPageDiv .noResults form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
}

.resultsPageDiv .noResults label  {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    text-align: left;
}

.resultsPageDiv .noResults input[type="text"] {
    border: none;
    padding: 0.85rem 0.7rem;
    outline: none;
    background-color: white;
    font-size: 1rem;
    border-radius: 10px;
    width: 300px;
    box-shadow: var(--DropShadow);
}

.resultsPageDiv .noResults button {
    margin-top: 1.5rem;
    padding: 0.5rem 3.5rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    cursor: pointer;
}

.warning {
    text-align: center;
    font-style: italic;
}
.warning button {
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: 1rem;
    margin-right: auto;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .resultsPageDiv {
        width: 90%;
        margin-top: 1.5rem;
    }
}