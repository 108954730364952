.requestBox {
    border: 3px var(--DarkGray) solid;
    padding: 1rem;
    border-radius: 15px;
    margin-bottom: 1.5rem;
}

.requestBox .buttonBox {
    margin-top: 1rem;
}

.requestBox button {
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
}
.requestBox button:first-of-type {
    margin-right: 1rem;
}

.requestBox input {
    margin-left: 0.5rem;
    padding: 0.25rem;
    width: 300px;
    box-sizing: border-box;
    border-radius: 5px;
}
.requestBox p {
    margin-bottom: 0.5rem;
}