.navBar {
    height: fit-content;
    box-shadow: var(--DropShadow);
    z-index: 100;
    background-color: white;
}

.navBarContent {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin: 0 2.5rem 0 2.5rem;
}

.navBar img {
    height: 100px;
}

.navBar .navBarContent button {
    cursor: pointer;
    background: none;
    border: solid 3px var(--DarkGray);
    margin: 0.5rem;
    padding: 0.6rem 1.2rem;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1rem;
}

.navBar .buttonsBlock {
    margin-left: auto;
    margin-right: 0;
}

/* Hamburger Men */
.mobile {
    display: none;
    position: relative;
}

@media only screen and (max-width: 1000px) {
    .navBarContent {
        grid-template-columns: auto minmax(0, 3fr) auto;
        margin: 0;
        margin: auto;
        width: 100vw;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .hamburgerMenu {
        position: absolute;
        background: var(--LightGray);
        box-shadow: var(--DropShadow);
        border-radius: 10px;
        width: 175px;
        display: flex;
        flex-direction: column;
        right: 0;
        margin-top: -0.5rem;
        text-align: center;
    }
    .navBar .navBarContent .mobile button {
        border: none;
        padding: 0;
    }
    .navBar .navBarContent .mobile button img {
        height: 35px;
    }
    .navBar img {
        height: 55px;
    }
    
  }