label {
    display: none;
}

.searchBar {
    background: #F5F5F5;
    width: fit-content;
    max-width: 80%;
    margin: auto;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    box-shadow: var(--DropShadow);
    display: flex;
    align-items: center;
    position: relative;
}

.searchBar input[type="text"] {
    border: none;
    background: none;
    font-size: 1rem;
    width: 25rem;
    max-width: 100%;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.searchBar input[type="text"]:focus-visible {
    outline: none;
}

.searchBar input[type="text"]::placeholder { 
  color: var(--DarkGray);
  font-weight: 600;
}
  
.searchBar input[type="image"] {
    height: 20px;
    margin-left: 0.5rem;
}

.autoComplete {
    position: absolute;
    background-color: var(--LightGray);
    color: var(--DarkGray);    
    text-align: left;
    top: 3rem;
    left: 0;
    width: 100%;
    border-radius: 25px;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    box-shadow: var(--DropShadow);
    z-index: 100;
}
.autoCompleteSuggestion {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;    
}
.autoComplete a {
    text-decoration: none;
    color: inherit;
}

@media only screen and (max-width: 1000px) {
    .searchBar input[type="image"] {
        display: none;
        margin-left: 0rem;
    }
    .searchBar {
        margin: auto;
    }
}