/* Main Image Block */
.mainImageBlock {
    background:  #55607C url('../images/city.jpg');
    background-position-y: center;
    background-size: cover;
    text-align: center;
    color: white;
}

.mainImageBlock h1 {
    font-size: 3rem;
    margin: 0;
}

.mainImageBlock .centeredInBlock {
    margin: auto;
    padding: 7.5rem 0;
}
/* Info Block */
.infoBlock {
    margin-left: 30%;
    margin-right: 30%;
    text-align: center;
}

.infoBlock h2 {
    font-size: 1.8rem;
    margin-top: 1.3rem;
}

.infoBlock button {
    margin-top: 1rem;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 18px;
    border: none;
    padding: 0.5rem 1.2rem;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: var(--DropShadow);
}

@media only screen and (max-width: 1000px) {
    .mainImageBlock .centeredInBlock h1 {
        font-size: 2rem;
        margin-bottom: 0.25rem;
    }
    .infoBlock {
        margin-left: 5%;
        margin-right: 5%;
    }
    .infoBlock p {
        margin-top: 1rem;
    }
}