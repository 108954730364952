.searchResultDiv {
    background: white;
    border-radius: 1.5rem;
    padding: 0.5rem 2rem;
    box-shadow: var(--DropShadow);
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
.searchResultDiv h1 {
    margin: 0;
}

.searchResultDiv h2 {
    margin: 0;
    font-weight: 500;
    font-size: 1.2rem;
}

.searchResultDiv button {
    background-color: #238056;
    border: none;
    color: white;
    width: fit-content;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    border-radius: 20px;
    cursor: pointer;
    margin-left: auto; 
    margin-right: 0;
}

.searchResultDiv a {
    margin-left: auto; 
    margin-right: 0;
}

@media only screen and (max-width: 1000px) {
    .searchResultDiv {
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
    }
    .searchResultDiv h1 {
        font-size: 1.5rem;
    }
    .searchResultDiv h2 {
        font-size: 1.2rem;
    }
    .searchResultDiv button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
}