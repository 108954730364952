.resetForm {
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: 80%;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
}
/* Text */
.resetForm label {
    display: block;
    margin-top: 1rem;
}
.resetForm p {
    margin-top: 1rem;
}

/* Inputs */
.resetForm input[type="password"] {
    border: none;
    padding: 0.5rem 0.5rem;
    outline: none;
    background-color: var(--LightGray);
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: var(--DropShadow);
}

.resetForm input[type="submit"] {
    margin-top: 1rem;
    padding: 0.5rem 3.5rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.error {
    color: rgb(214, 71, 71);
    margin-top: 1rem;
    text-align: center;
}

.center {text-align: center;}