.companyPageDiv {
    width: 50%;
    margin: auto;
    margin-top: 2.5rem;
}

.companyPageDiv a {
    text-decoration: none;
    color: var(--DarkGray)
}

.companyPageDiv h1, h2 {
    margin: 0;
}

.companyPageDiv h2 {
    font-weight: 500;
}


/* Review Block */
.companyPageDiv .reviewBlock {
    margin-top: 3rem;
}

.companyPageDiv .reviewBlock h2 {
    font-weight: 600;
    font-size: 1.6rem;
}

/* Review Block Top Bar */
.companyPageDiv .reviewBlock .topBlock {
    display: flex;
    justify-content: space-between;
}

/* Review Content */
.reviewContent {
    text-align: center;
}

.reviewContent button {
    background-color: #238056;
    border: none;
    color: white;
    width: fit-content;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem 1.2rem;
    border-radius: 10px;
    cursor: pointer;
    margin-left: auto; 
    margin-right: 0;
    margin-top: 1rem;
}

.leaveReviewBtn {
    background-color: var(--PrimaryBlue);
    border: none;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .companyPageDiv {
        width: 90%;
    }
}