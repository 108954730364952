.loginForm {
    display: flex;
    flex-direction: column;
}
/* Top Bar */
.topBar {
    display: flex;
    justify-content: space-between;
}
.topBar button {
    background: none;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
}

/* Bottom Bar */
.bottomBar {
    display: flex;
    margin-top: 1rem;
}

.bottomBar button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 1rem;
}

/* Text */
.loginForm label {
    display: block;
    margin-top: 1rem;
}

/* Inputs */
.loginForm input[type="text"], .loginForm input[type="password"], .loginForm input[type="email"] {
    border: none;
    padding: 0.5rem 0.5rem;
    outline: none;
    background-color: var(--LightGray);
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: var(--DropShadow);
}

.loginForm input[type="submit"] {
    margin-top: 1rem;
    padding: 0.5rem 3.5rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.loginForm input[type='checkbox'] {
    margin-left: 0;
    margin-right: auto;
    width: 22px;
    height:22px;
    background:white;
    border-radius:10px;
    border:2px solid #555;
}

.error {
    color: rgb(214, 71, 71);
    margin-top: 1rem;
    text-align: center;
}