.adminDiv {
    width: 50%;
    margin: auto;
    margin-top: 2.5rem;
}

.adminDiv form  {
    display: flex;
    flex-direction: column;
}

.adminDiv label {
    display: block;
    margin-top: 1rem;
}

/* Inputs */
.adminDiv input[type="text"], .adminDiv textarea {
    border: none;
    padding: 0.5rem 0.5rem;
    outline: none;
    background-color: var(--LightGray);
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: var(--DropShadow);
}

.adminDiv textarea {
    height: 250px;
    font-family: sans-serif;
}

.adminDiv input[type="submit"] {
    margin-top: 1rem;
    padding: 0.5rem 3.5rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    margin-bottom: 2.5rem;
}


@media only screen and (max-width: 1000px) {
    .adminDiv {
        width: 90%;
    }
}