.reviewDiv {
    background-color: white;
    border-radius: 15px;
    box-shadow: var(--DropShadow);
    padding: 1rem 1.5rem;
    margin-top: 1.5rem;
}

.reviewDiv h3, p {
    margin:  0;
}

.reviewDiv .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviewDiv .row2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.reviewDiv .row2 button {
    margin-left: 0;
    margin-right: auto;
    margin: 0;
    font-size: 1rem;
    background-color: var(--PrimaryBlue);
}

.starBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.starBlock button {
    background-color: rgb(195, 61, 61);
    padding: 0.5rem;
    margin-top: 0;
    margin-left: 0.5rem;
}

.successBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reviewDiv .row2 img {
    max-height: 30px;
    margin-left: 0.75rem;
}

/* Review Modal */
.reviewModalContent {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    max-width: 900px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05),
            0 16px 16px rgba(0, 0, 0, 0.05);
  }

.reviewModalContent .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

.reviewModalContent .topBar .starImage {
    max-height: 30px;
    margin-right: 0.75rem;
}

.reviewModalContent .topBar p {
    margin-right: 0.75rem;
}


.reviewModalContent .topBar h3 {
    font-size: 1.5rem;
    margin: 0;
    margin-top: 1rem;
}


.reviewModalContent .infoBlock {
    text-align: left;
}
.reviewModalContent .infoBlock p {
    margin-top: 1rem;
}

.reviewModalContent .closeBtn {
    background: none;
    color: var(--DarkGray);
    padding: 0;
    margin: 0;
    margin-left: auto;
    margin-right: 0;
    font-size: 1.5rem;
}


@media only screen and (max-width: 1000px) {
    .reviewDiv {
        padding: 0.75rem 1rem;
    }
    .reviewDiv .row2 {
        flex-direction: column-reverse;
        margin-top: 0.5rem;
    }
    .reviewDiv .row2 button {
        margin-top: 0.5rem;
    }
    .reviewModalContent .topBar h3 {
        margin-top: 0;
    }
    .reviewModalContent .topBar {
        display: flex;
        flex-direction: column;
    }
    .reviewModalContent .successBox {
        display: flex;
        flex-direction: column;
    }
    .reviewModalContent .infoBlock p b {
        display: block;
    }
    
}