/* Review Modal */
.modal {
    display: block; /* Hidden by default */
    position: absolute; /* Stay in place */
    z-index: 100; /* Sit on top */
    padding-top: 50px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: fit-content; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.089); /* Black w/ opacity */
    backdrop-filter: blur(2px);
    overflow: hidden;
  }

  
.leaveReviewContent {
    display: flex;
    flex-direction: column;
    background-color: #fefefe;
    height: fit-content;
    overflow-y: visible;
    margin: auto;
    margin-bottom: 5rem;
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    max-width: 900px;
    box-shadow: 0 1px 1px rgba(0,0,0,0.05), 
            0 2px 2px rgba(0,0,0,0.05), 
            0 4px 4px rgba(0,0,0,0.05), 
            0 8px 8px rgba(0,0,0,0.05),
            0 16px 16px rgba(0, 0, 0, 0.05);
  }

.leaveReviewContent form {
    margin: auto;
}

.leaveReviewContent button {
    margin-left: auto;
    margin-right: 0;
    display: block;
    background: none;
    border: none;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
}

.leaveReviewContent form h2 {
    text-align: center;
}

.leaveReviewContent form h4 {
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
    font-weight: 400;
}

.leaveReviewContent label {
    display: block;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.leaveReviewContent .sameLine label {
    display: block;
    margin-top: 0rem;
    margin-bottom: 0rem;
    margin-right: 1rem;
}


.leaveReviewContent .sameLine {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.leaveReviewContent form input, .leaveReviewContent form select {
    border: none;
    padding: 0.5rem 0.5rem;
    outline: none;
    background-color: var(--LightGray);
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: var(--DropShadow);
}

.leaveReviewContent form input[type="text"] {
    box-sizing: border-box;
    width: 100%;
}

.leaveReviewContent input[type='checkbox'] {
    margin-left: 0;
    margin-right: auto;
    width: 22px;
    height:22px;
    background:white;
    border-radius:10px;
    border:2px solid #555;
}

/* Always Show Arrows */
.leaveReviewContent form input[type=number]::-webkit-inner-spin-button, 
.leaveReviewContent form  input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.leaveReviewContent form input[type="submit"] {
    margin-top: 1.8rem;
    margin-bottom: 1rem;
    padding: 0.5rem 3.5rem;
    border: none;
    background-color: var(--PrimaryBlue);
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 15px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    display: block;
}

.leaveReviewContent form textarea {
    resize: none;
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    font-family: capitana, sans-serif;
    padding: 0.5rem 1rem;
    border: none;
    outline: none;
    background-color: var(--LightGray);
     border-radius: 10px;
    box-shadow: var(--DropShadow);
}